/* You can add global styles to this file, and also import other style files */

/*@import "@angular/material/prebuilt-themes/indigo-pink.css";*/
@import '@angular/material/prebuilt-themes/indigo-pink.css';
/*@import "~material-design-icons/iconfont/material-icons.css";*/
@import "assets/mat-icons.css";

/*@import "primeicons/primeicons.css";
@import "primeng/resources/themes/nova-light/theme.css";
@import "primeng/resources/primeng.min.css";*/

html, body { height: 100%; }
body { 
    margin: 0;
    background-color: #e2e2e2;
    font-family: Roboto, "Helvetica Neue", sans-serif; 
}


